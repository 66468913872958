// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Tooltip from '@mui/material/Tooltip';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';

import CountUp from 'react-countup';
import { Icon } from '@iconify/react';

const gradient = {
  1: 'linear-gradient(90deg, rgba(240, 61, 117, 0.55),rgba(245,0,0,0.45))',
  2: 'linear-gradient(90deg, rgba(37, 201, 238, 0.35), rgba(37, 100, 238, 0.35))',
  3: 'linear-gradient(90deg, rgba(37, 238, 175, 0.35), rgba(100, 238, 37, 0.35))'
};

function ExampleCard({ color, icon, name, count, help, prefix, suffix, ...rest }) {
  const imageTemplate = (
    <MKBox
      // bgColor="white"
      borderRadius="xl"
      shadow="lg"
      minHeight="10rem"
      sx={{
        minHeight: '165px',
        display: 'flex',
        textAlign: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        padding: '10px',
        overflow: 'hidden',
        transform: 'perspective(999px) rotateX(0deg) translate3d(0, 0, 0)',
        transformOrigin: '50% 0',
        backfaceVisibility: 'hidden',
        willChange: 'transform, box-shadow',
        background: gradient[color],
        transition: 'transform 200ms ease-out',

        '&:hover': {
          transform: 'perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)'
        }
      }}
      {...rest}>
      {help && (
        <MKBox position="absolute" top={0} right={0} zIndex={2} p={1}>
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="lock-black" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <circle id="Oval" fill="#1F2937" cx="12" cy="12" r="12" />
              <g xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="white"
                  stroke="white"
                  d="M502.29,788.199h-47c-33.1,0-60,26.9-60,60v64.9c0,33.1,26.9,60,60,60h47c33.101,0,60-26.9,60-60v-64.9   C562.29,815,535.391,788.199,502.29,788.199z"
                />
                <path
                  fill="white"
                  stroke="white"
                  d="M170.89,285.8l86.7,10.8c27.5,3.4,53.6-12.4,63.5-38.3c12.5-32.7,29.9-58.5,52.2-77.3c31.601-26.6,70.9-40,117.9-40   c48.7,0,87.5,12.8,116.3,38.3c28.8,25.6,43.1,56.2,43.1,92.1c0,25.8-8.1,49.4-24.3,70.8c-10.5,13.6-42.8,42.2-96.7,85.9   c-54,43.7-89.899,83.099-107.899,118.099c-18.4,35.801-24.8,75.5-26.4,115.301c-1.399,34.1,25.8,62.5,60,62.5h49   c31.2,0,57-23.9,59.8-54.9c2-22.299,5.7-39.199,11.301-50.699c9.399-19.701,33.699-45.701,72.699-78.1   C723.59,477.8,772.79,428.4,795.891,392c23-36.3,34.6-74.8,34.6-115.5c0-73.5-31.3-138-94-193.4c-62.6-55.4-147-83.1-253-83.1   c-100.8,0-182.1,27.3-244.1,82c-52.8,46.6-84.9,101.8-96.2,165.5C139.69,266.1,152.39,283.5,170.89,285.8z"
                />
              </g>
            </g>
          </svg>
        </MKBox>
      )}
      <>
        <MKBox
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(90deg, rgba(255,255,255,0.775),  rgba(255,255,255, 1))',
            backdropFilter: 'saturate(200%) blur(30px)',
            width: 50,
            height: 50,
            borderRadius: 2,
            border: 'none',
            mb: 1
          }}>
          <Icon heigh={30} width={30} icon={`${icon}`} color="#2a3f4d" />
        </MKBox>
        <MKBox flexGrow={1}>
          <MKTypography
            variant="h6"
            fontWeight="bold"
            color="white"
            sx={{ textTransform: 'uppercase', fontSize: 12, color: '#2a3f4d' }}>
            {name}
          </MKTypography>
          <CountUp
            style={styles.counter}
            start={0}
            end={count}
            delay={Math.floor(Math.random() * 11)}
            prefix={prefix}
          />
          <MKTypography
            variant="h6"
            fontWeight="bold"
            color="white"
            sx={{ fontSize: 12, lineHeight: '16px', color: '#2a3f4d' }}>
            {suffix}
          </MKTypography>
        </MKBox>
      </>
    </MKBox>
  );

  return (
    <MKBox position="relative">
      {help ? (
        <Tooltip title="Pro Element" placement="top">
          {imageTemplate}
        </Tooltip>
      ) : (
        imageTemplate
      )}
    </MKBox>
  );
}

// Setting default props for the ExampleCard
ExampleCard.defaultProps = {
  color: 3,
  icon: 'ph:lightning-fill',
  name: '',
  count: 0,
  help: false,
  prefix: '',
  suffix: ''
};

// Typechecking props for the ExampleCard
ExampleCard.propTypes = {
  color: PropTypes.oneOf([1, 2, 3]),
  icon: PropTypes.string,
  name: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  count: PropTypes.number,
  help: PropTypes.bool
};

export default ExampleCard;

const styles = {
  counter: {
    fontSize: 42,
    fontWeight: 'bold',
    lineHeight: 1,
    color: '#344767'
  }
};

// linear-gradient(90deg, #f03d75, #f50000d4) // #d0104c

// #64ee25  #25eeaf

// #25c9ee  #2564ee
