/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';

const UsageInputs = ({ sqft = 0, onChange }) => {
  const [update, setUpdate] = useState({ people: 2, usage: 0 });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUpdate({ ...update, [name]: value });
    onChange({ ...update, [name]: value });
  };

  return (
    <Box sx={{ width: '100%', flexDirection: 'column' }}>
      <Typography sx={styles.label}>SQFT</Typography>
      <TextField
        sx={styles.ftInput}
        inputProps={{ style: { fontSize: 26, fontWeight: 'bolder' } }}
        InputLabelProps={{ style: { fontSize: 26 } }}
        id="size"
        placeholder="0"
        value={sqft}
        type="number"
        InputProps={{
          readOnly: true
        }}
        variant="standard"
      />
      <FormControl variant="standard" fullWidth>
        <Typography sx={styles.label}>People</Typography>
        <Select
          labelId="people-label"
          id="people"
          name="people"
          sx={styles.select}
          value={update.people}
          onChange={handleChange}>
          {[1, 2, 3, 4, 5, 6].map((num) => (
            <MenuItem key={num} sx={{ fontSize: 20 }} value={num}>
              {num}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ width: '100%' }}>
        <Typography
          sx={{
            mb: 0,
            fontSize: 14,
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
          id="non-linear-slider"
          gutterBottom>
          Usage
        </Typography>
        <Typography sx={{ fontSize: 26, fontWeight: 'bold' }}>
          {update.usage === 0 ? 'Average' : `${update.usage}%`}
        </Typography>
        <Slider
          name="usage"
          aria-label="usage"
          defaultValue={0}
          step={1}
          min={-20}
          max={20}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
};

export default UsageInputs;

const styles = {
  label: {
    mb: 0,
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  ftInput: {
    fontSize: 26,
    width: '100%',
    fontWeight: 'bold',
    p: 0,
    m: 0
  },
  select: {
    fontSize: 26,
    fontWeight: 'bolder'
  }
};
