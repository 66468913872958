/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui material components
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AlertModal = ({ alert, onClose }) => {
  const [visible, setVisible] = useState(true);
  const { title, message } = alert;

  useEffect(() => {
    const { open } = alert;
    if (open) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [alert]);

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;

AlertModal.propTypes = {
  alert: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

AlertModal.defaultProps = {
  alert: {
    visible: false,
    title: 'Something Went Wrong',
    message: 'Please try again.'
  },
  onClose: () => {}
};
