/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { getClientLocation } from 'utilities';

const GOOGLE_MAPS_API_KEY = 'AIzaSyC-MO6rbNxEt5cqNgobFi9cs0MsIEWv85Y';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const AddressBar = ({ onComplete }) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const autocompleteService = useRef(null);

  // eslint-disable-next-line no-unused-vars
  function gmapsLoaded() {
    console.log('gmaps loaded');
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&callback=Function.prototype`,
          document.querySelector('head'),
          'google-maps'
        );
      }

      loaded.current = true;
    }
  }, []);

  const formatAddress = (structured) => {
    const street = structured.main_text;
    const [city, state, country] = structured.secondary_text.split(', ');

    const newObject = {
      street,
      city,
      state,
      country
    };
    return newObject;
    // setFormatedAddress(newObject); // Make sure to define and use setFormatedAddress
  };

  const getLocation = async (data) => {
    try {
      const res = await geocodeByAddress(data);
      const { lat, lng } = await getLatLng(res[0]);
      const coords = {
        geo: {
          type: 'Point',
          coordinates: [lng, lat]
        },
        coordinates: { lat, lng }
      };
      return coords;
    } catch (error) {
      console.log('get location error: ', error);
    }
  };

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        if (autocompleteService.current) {
          autocompleteService.current.getPlacePredictions(request, callback);
        }
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return;
    }

    fetch(
      {
        input: inputValue,
        options: {
          componentRestrictions: { country: 'us' },
          fields: ['formatted_address', 'description'],
          strictBounds: false,
          types: ['address']
        }
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleOptionChange = async (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);

    if (newValue && newValue.description) {
      const clientGeo = await getClientLocation();
      console.log('client geo: ', clientGeo);
      const coords = await getLocation(newValue?.description);
      const address = formatAddress(newValue?.structured_formatting);
      onComplete({ address, clientGeo, ...coords });
    }
  };

  const renderOption = (props, option) => {
    const matches = option.structured_formatting.main_text_matched_substrings || [];
    const parts = parse(
      option.structured_formatting.main_text,
      matches.map((match) => [match.offset, match.offset + match.length])
    );

    return (
      <li {...props}>
        <Grid container alignItems="center">
          <Grid item sx={{ display: 'flex', width: 44 }}>
            <LocationOnIcon sx={{ color: 'text.secondary' }} />
          </Grid>
          <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
            {parts.map((part, index) => (
              <Box
                key={index}
                component="span"
                sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                {part.text}
              </Box>
            ))}
            <Typography variant="body2" color="text.secondary">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      </li>
    );
  };

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{
        width: { xs: '100%', sm: '100%', md: '100%' },
        backgroundColor: 'rgba(0, 0, 160, 0.055)'
      }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={handleOptionChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          placeholder="Enter your address"
          sx={{ fontSize: 16, color: '#000' }}
          hiddenLabel
          {...params}
          fullWidth
        />
      )}
      renderOption={renderOption}
    />
  );
};

export default AddressBar;

AddressBar.propTypes = {
  onComplete: PropTypes.func
};
