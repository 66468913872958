import * as React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function PropertyStats({ stats = [] }) {
  return (
    <List dense sx={{ mt: 2, width: '90%' }}>
      {stats &&
        stats.map((item) => {
          if (item.value) {
            const { id, label, value } = item;
            return (
              <ListItem key={`stats-${id}`} sx={{ mb: 0.5 }} disablePadding>
                <ListItemText
                  sx={styles.label}
                  id={`stats-label-${label}`}
                  primary={`${label.toUpperCase()}`}
                  disableTypography
                />
                <ListItemText
                  sx={styles.value}
                  id={`stats-value-${value}`}
                  primary={item.value}
                  disableTypography
                />
              </ListItem>
            );
          }
        })}
    </List>
  );
}

PropertyStats.propTypes = {
  stats: PropTypes.array
};

const styles = {
  label: {
    fontSize: 12,
    fontWeight: '600',
    color: '#111'
  },
  value: {
    fontSize: 12,
    fontWeight: '700',
    color: '#000',
    textAlign: 'right'
  }
};
