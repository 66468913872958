import getBrowserFingerprint from 'get-browser-fingerprint';
import { service } from 'services/api';

const generateNewSession = async () => {
  const newSession = {
    uuid: self?.crypto?.randomUUID(),
    fingerprint: getBrowserFingerprint()
  };

  try {
    const response = await service.getSession(newSession);
    if (response?.data?.sessionId) {
      newSession.sessionId = response.data.sessionId;
    }
  } catch (error) {
    console.error('Error while getting session:', error);
  }

  const serializedSession = btoa(JSON.stringify(newSession));
  localStorage.setItem('session', serializedSession);
  return newSession;
};

export const checkSession = () => {
  const local = localStorage.getItem('session');
  if (local) {
    const data = JSON.parse(atob(local));
    return data;
  } else {
    return generateNewSession();
  }
};

export const getClientLocation = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      // reject(new Error('Geolocation is not supported.'));
      console.log(
        'Geolocation is not supported!  You should enable location tracking in your browser.'
      );
      resolve(null);
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const geo = {
            type: 'Point',
            coordinate: [position.coords.longitude, position.coords.latitude]
          };
          console.log('BROWSER GEO', geo);
          resolve(geo);
        },
        (error) => {
          console.log('Error while getting location, please enable location: ', error);
          // reject(error);
          resolve(null);
        }
      );
    }
  });
};
