/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useCallback } from 'react';
// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import Mapbox from 'components/Mapbox';
// import GridReveal from 'components/GridReveal';
// import Pulse from './Pulse';
import PropertyDetails from '../components/PropertyDetails';
import UsageInputs from '../components/UsageInputs';

const InfoBlock = ({ coordinates, estimate, onChange }) => {
  const { sqft } = estimate || {};
  const [gridAction, setGridAction] = React.useState('');
  const [mapAction, setMapAction] = React.useState('');

  // const handleGridStart = useCallback(() => {
  //   setMapAction('detect');
  // }, []);

  // const handleMapEvent = useCallback((e) => {
  //   switch (e) {
  //     case 'loaded':
  //       console.log('map event: loaded');
  //       setGridAction('start');
  //       break;
  //     case 'detected':
  //       console.log('map event: detected');
  //       setGridAction('exit');
  //       break;
  //     case 'detect-fail':
  //       console.log('map event: detect-fail');
  //       break;
  //     default:
  //       break;
  //   }
  // }, []);

  const handleUpdate = useCallback((e) => {
    console.log('handleUpdate', e);
  }, []);

  return (
    <MKBox component="section" py={{ xs: 2, sm: 2, md: 2 }}>
      <MKBox
        variant="gradient"
        // bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{
          overflow: 'hidden',
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.55),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl
        }}>
        <Container sx={{ position: 'relative', zIndex: 2, py: 2 }}>
          <Grid container item xs={12} lg={10} sx={{ mx: 'auto' }}>
            <MKBox width="100%" component="form" method="post" autoComplete="off">
              <MKBox p={3}>
                <Grid container spacing={2} justify="flex-start">
                  <Grid item xs={12} md={3} order={{ xs: 2, sm: 2 }}>
                    {estimate && estimate?.address && <PropertyDetails estimate={estimate} />}
                  </Grid>
                  <Grid item xs={12} md={3} order={{ xs: 3, sm: 3 }}>
                    {estimate && estimate?.address && (
                      <UsageInputs sqft={sqft} onChange={onChange} />
                    )}
                  </Grid>
                  <Grid
                    item
                    justifyContent="center"
                    xs={12}
                    sm={12}
                    md={6}
                    order={{ xs: 1, sm: 1, md: 3, lg: 3 }}>
                    <MKBox
                      display="flex"
                      justifyContent="center"
                      sx={{
                        minHeight: 260,
                        maxWidth: 460,
                        backgroundColor: '#888',
                        borderRadius: 2,
                        overflow: 'hidden',
                        position: 'relative'
                      }}>
                      {/* <Pulse /> */}
                      {/* <GridReveal action={gridAction} onStart={handleGridStart} />
                      <Mapbox event={handleMapEvent} action={mapAction} /> */}
                      <Mapbox coordinates={coordinates} />
                    </MKBox>
                  </Grid>
                </Grid>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
};

export default InfoBlock;

const inputProps = {
  style: {
    color: '#000',
    label: '#111',
    fontSize: 14,
    fontWeight: '600'
  }
};

const style = {
  labelStyle: {
    '& .MuiFormControlLabel-label': {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.75)'
    }
  },
  input: {
    backgroundColor: 'rgba(255,255, 255, 0.95)',
    borderRadius: 2
  }
};
