/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from 'prop-types';

// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';

// Presentation page components
import DataCard from 'pages/Presentation/components/DataCard';

// Data
import { formatData } from 'pages/Presentation/sections/data/estimateData';

function DataBlocks({ estimate }) {
  const data = formatData(estimate);
  const renderData = data.map(({ title, description, items, color }) => (
    <Grid container spacing={2} sx={{ mb: 4 }} key={title}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h4" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={2}>
          {items.map(({ icon, name, count, route, prefix, suffix }) => (
            <Grid item xs={6} md={3} sx={{ mb: 2 }} key={name}>
              <Link to={route}>
                <DataCard
                  color={color}
                  icon={icon}
                  name={name}
                  count={count}
                  prefix={prefix}
                  suffix={suffix}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={4} py={4}>
      <Container sx={{ mt: 4 }}>{renderData}</Container>
    </MKBox>
  );
}

export default DataBlocks;

DataBlocks.propTypes = {
  estimate: PropTypes.object
};
