/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';
import MKInput from 'components/MKInput';

// Images
import bgImage from 'assets/images/shapes/waves-white.svg';

const defaultContact = {
  timeline: 1,
  first: '',
  last: '',
  telephone: '',
  email: ''
};

const ContactForm = ({ onRequest }) => {
  const [disabled, setDisabled] = React.useState(true);
  const [contact, setContact] = React.useState(defaultContact);

  const handleChange = (e) => {
    if (e.target.name === 'timeline') {
      setContact({ ...contact, [e.target.name]: parseInt(e.target.value) });
    } else {
      setContact({ ...contact, [e.target.name]: e.target.value });
    }
  };

  const handleForm = useCallback(() => {
    console.log('HANDLE FORM: ', contact);
    onRequest(contact);
  }, [contact]);

  useEffect(() => {
    const { telephone, first, last } = contact;

    if (telephone && first && last) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [contact]);

  return (
    <MKBox id="request-call" component="section" py={{ xs: 0, sm: 6, md: 0 }}>
      <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{ overflow: 'hidden' }}>
        <MKBox
          component="img"
          src={bgImage}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
          opacity={0.1}
        />
        <Container sx={{ position: 'relative', zIndex: 2, py: 2 }}>
          <Grid container item xs={12} md={6} justifyContent="center" mx="auto" textAlign="center">
            <MKTypography variant="h3" color="white" mt={2}>
              Ready to install?
            </MKTypography>
            <MKTypography variant="body2" color="white" mb={2}>
              We are here to help you with every step of your solar journey.
            </MKTypography>
          </Grid>
          <Grid container item xs={12} lg={10} sx={{ mx: 'auto' }}>
            <MKBox width="100%" component="form" method="post" autoComplete="off">
              <MKBox p={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel id="install-radio" sx={{ color: '#fff', fontSize: 16 }}>
                        Timeline:
                      </FormLabel>
                      <RadioGroup
                        sx={{ flexDirection: { xs: 'column', md: 'row' } }}
                        aria-labelledby="install-radio"
                        name="timeline"
                        type="number"
                        onChange={handleChange}
                        defaultValue={1}>
                        <FormControlLabel
                          sx={style.labelStyle}
                          value={1}
                          type="number"
                          control={<Radio />}
                          label="ASAP"
                        />
                        <FormControlLabel
                          sx={style.labelStyle}
                          value={2}
                          type="number"
                          control={<Radio />}
                          label="NEXT 3 MONTHS"
                        />
                        <FormControlLabel
                          sx={style.labelStyle}
                          value={3}
                          type="number"
                          control={<Radio />}
                          label="NOT SURE"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid item xs={12} md={12} mb={2}>
                      <MKInput
                        inputProps={inputProps}
                        sx={style.input}
                        id="first"
                        name="first"
                        placeholder="First Name"
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MKInput
                        inputProps={inputProps}
                        sx={style.input}
                        id="last"
                        name="last"
                        placeholder="Last Name"
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Grid item xs={12} md={12} mb={2}>
                      <MKInput
                        inputProps={inputProps}
                        sx={style.input}
                        id="telephone"
                        name="telephone"
                        type="tel"
                        placeholder="Telephone"
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MKInput
                        inputProps={inputProps}
                        sx={style.input}
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid item justifyContent="center" xs={12} md={3}>
                    <MKButton
                      fullWidth
                      variant="gradient"
                      color="info"
                      size="large"
                      component="a"
                      disabled={disabled}
                      onClick={handleForm}
                      sx={{ minHeight: { md: 100 }, opacity: disabled ? 0.45 : 1 }}>
                      Requst a call
                    </MKButton>
                  </Grid>
                </Grid>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
};

export default ContactForm;

ContactForm.propTypes = {
  onRequest: PropTypes.func
};

ContactForm.defaultProps = {
  onRequest: () => {}
};

const inputProps = {
  style: {
    color: '#000',
    label: '#111',
    fontSize: 14,
    fontWeight: '600'
  }
};

const style = {
  labelStyle: {
    '& .MuiFormControlLabel-label': {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 12,
      color: '#fff'
    }
  },
  input: {
    backgroundColor: 'rgba(255,255, 255, 0.95)',
    borderRadius: 2
  }
};
