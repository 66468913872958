/* eslint-disable react/prop-types */
import * as React from 'react';
import Map from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// add fog
// https://docs.mapbox.com/mapbox-gl-js/example/add-fog/
// const FillLayer = {
//   id: 'add-3d-buildings',
//   source: 'composite',
//   'source-layer': 'building',
//   filter: ['==', 'extrude', 'true'],
//   type: 'fill-extrusion',
//   minzoom: 15,
//   paint: {
//     'fill-extrusion-color': 'orange',

//     // Use an 'interpolate' expression to
//     // add a smooth transition effect to
//     // the buildings as the user zooms in.
//     'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
//     'fill-extrusion-base': [
//       'interpolate',
//       ['linear'],
//       ['zoom'],
//       15,
//       0,
//       15.05,
//       ['get', 'min_height']
//     ],
//     'fill-extrusion-opacity': 0.6
//   }
// };

const MAPBOX_TOKEN =
  'pk.eyJ1IjoibmV3bWVkaWFoYXVzIiwiYSI6ImNsODZvZ2dwczBkbm0zb3BpNDg2NWNxNmQifQ.Kbx4K88fv3-n_5IV7t3i1Q';

// const markerImage = 'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png';

const MapBox = ({ coordinates }) => {
  const [isAtStart, setIsAtStart] = React.useState(true);
  const [isZoomed, setIsZoomed] = React.useState(false);
  const [endLocation, setEndLocation] = React.useState(false);
  const { lat = 0, lng = 0 } = coordinates || {};
  const mapRef = React.useRef(null);

  React.useEffect(() => {
    if (endLocation) {
      mapRef.current.flyTo({
        ...endLocation,
        duration: 2000,
        essential: true // This animation is considered essential with
        //respect to prefers-reduced-motion
      });
    }
  }, [endLocation]);

  React.useEffect(() => {
    const start = {
      center: [-82, 40],
      zoom: 1,
      pitch: 0,
      bearing: 0
    };
    const mid = {
      center: [lng, lat],
      zoom: 20,
      bearing: 130,
      pitch: 75
    };
    const end = {
      center: [lng, lat],
      zoom: 19,
      bearing: 0,
      pitch: 0
    };

    const target = isAtStart ? mid : start;

    if (lat && lng) {
      setIsAtStart(true);
      // aim for point a or b
      mapRef.current.flyTo({
        ...target, // Fly to the selected target
        duration: 10000, // 10 seconds
        essential: true // This animation is considered essential with
        //respect to prefers-reduced-motion
      });

      mapRef.current.on('moveend', ({ originalEvent }) => {
        if (originalEvent) {
          // User event
        } else {
          setEndLocation(end);
        }
      });
    }
  }, [lat, lng]);

  return (
    <Map
      ref={mapRef}
      mapboxAccessToken={MAPBOX_TOKEN}
      initialViewState={{
        longitude: -82,
        latitude: 40,
        zoom: 1,
        // 3d properties
        pitch: 0,
        bearing: 0
      }}
      // mapStyle="mapbox://styles/mapbox/light-v11"
      // mapStyle="mapbox://styles/mapbox/satellite-v9"
      mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
      style={mapStyle}>
      {/* <Layer {...FillLayer} /> */}
      {/* {startPulse && (
        <Layer longitude={lng} latitude={lat}>
          <Pulse />
        </Layer>
      )} */}
    </Map>
  );
};

export default React.memo(MapBox);

const mapStyle = {
  width: 460,
  height: 260,
  borderRadius: 8,
  backgroundColor: 'whitesmoke',
  overflow: 'hidden'
};
