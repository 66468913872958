/* eslint-disable react/prop-types */
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { service } from 'services/api';
import { checkSession } from 'utilities';

const peopleMultiplier = {
  1: 0.95555,
  2: 1,
  3: 1.15687,
  4: 1.24715,
  5: 1.34097,
  6: 1.56151
};

const errorAlert = {
  open: true,
  title: 'Something Went Wrong',
  message: 'Please try again.'
};

const calculateSolar = (yearUsage) => {
  const totalKWH = yearUsage;
  let totalPanels = totalKWH / 365 / 1.9; // calculatePanels(totalKWH);
  totalPanels = totalPanels.toFixed(0);
  let totalCost = totalPanels * 400 * 2.7; // totalKWH * 1.125; // calculateCost(totalPanels);
  totalCost = totalCost * 1.029; // dealer fee
  totalCost = Math.round(totalCost * 100) / 100;
  totalCost = totalCost + 800.0; // Palmetto fee
  let monthlyCost = (totalKWH * 0.105) / 12; // calculateMonthly(totalCost);
  monthlyCost = Math.round(monthlyCost * 100) / 100;

  const solar = {
    panels: {
      total: totalPanels,
      output: 400,
      unit: 'Watts'
    },
    energy: {
      offset: 0,
      year: 0,
      month: 0
    },
    cost: {
      net: totalCost,
      savings: 0,
      month: monthlyCost,
      lifetimeSavings: 0
    },
    offset: {
      co2: 0,
      trees: 0,
      cars: 0,
      miles: 0
    }
  };
  return solar;
};

const calculate = (estimate, data) => {
  if (!estimate?.usage?.energy) {
    return;
  }
  const { usage } = estimate;
  const { energy, cost } = usage;
  const { month } = energy;
  const { month: costMonth } = cost;
  console.log('ENERGY MONTH', month);
  const energyRate = costMonth / month;
  const ENERGY_RATE = 0.14411; // 0.14411; // Energy rate per kWh
  console.log('ENERGY COST RATE', energyRate);

  const { people, usage: newUsage } = data;

  const usagePercent = newUsage / 100;
  console.log('USAGE PERCENT', usagePercent);
  const totalPeople = peopleMultiplier[people];

  let energyUsage = totalPeople * month;
  // const newMonthlyEnergy = Math.round((totalUsage / month) * (1 + usagePercent));
  let percentCalc = 0;
  if (usagePercent != 0) {
    percentCalc = energyUsage * usagePercent;
    console.log('PERCENT CALC', percentCalc);
    energyUsage = energyUsage + percentCalc;
  }

  const newMonthlyCost = energyUsage * ENERGY_RATE;
  const newYearlyEnergy = Math.round(energyUsage * 12);
  const newUsageData = {
    usage: {
      energy: {
        month: Math.round(energyUsage),
        year: newYearlyEnergy
      },
      cost: {
        month: Math.round(newMonthlyCost * 100) / 100,
        year: Math.round(newMonthlyCost * 12 * 100) / 100
      }
    }
  };
  return newUsageData;
};

const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const [session, setSession] = useState({});
  const [cache, setCache] = useState({});
  const [estimate, setEstimate] = useState({});
  const [alert, setAlert] = useState({});
  const [place, setPlace] = useState({});

  const reCalculate = useCallback(
    async (data) => {
      if (!cache?.usage?.energy) {
        return;
      }
      const newUsageData = calculate(cache, data);
      const newSolarData = calculateSolar(newUsageData.usage.energy.year);
      setEstimate({
        ...estimate,
        usage: { ...estimate.usage, ...newUsageData.usage },
        solar: newSolarData
      });
    },
    [estimate]
  );

  const getEstimate = useCallback(async () => {
    if (!place.address) {
      return;
    }
    // console.log('GET ESTIMATE CALLED: ', session, place);
    setEstimate({});
    setCache({});
    try {
      const response = await service.getEstimate({ session, place });
      // console.log('GET ESTIMATE RESPONSE:', response);
      if (response.data) {
        setCache(response.data);
        setEstimate(response.data);
      } else {
        setAlert(errorAlert);
      }
    } catch (error) {
      console.log('ERROR', error);
      setAlert(errorAlert);
    }
  }, [session, place]);

  const postContact = useCallback(
    async (contact) => {
      if (estimate?.propertyKey) {
        contact.propertyKey = estimate.propertyKey;
      }
      try {
        const response = await service.postContact({ ...session, ...contact });
        console.log('POST CONTACT RESPONSE', response);
        if (response.data) {
          setAlert({
            open: true,
            title: 'Thank You',
            message: 'We will be in touch shortly.'
          });
        } else {
          setAlert(errorAlert);
        }
      } catch (error) {
        console.log('ERROR', error);
        setAlert(errorAlert);
      }
    },
    [session, estimate]
  );

  const initSession = useCallback(async () => {
    const sessionData = await checkSession();
    setSession(sessionData);
  }, []);

  const value = {
    alert,
    estimate,
    place,
    setAlert,
    setPlace,
    getEstimate,
    postContact,
    reCalculate
    // loading: state.loading,
    // error: state.error,
    // getEnergy
  };

  useEffect(() => {
    if (place.address) {
      // getEstimate();
      setEstimate({});
    }
  }, [place]);

  useEffect(() => {
    initSession();
  }, []);

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

export { StoreProvider };
export default StoreContext;
