// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedinIcon from '@mui/icons-material/LinkedIn';

// Material Kit 2 React components
import MKTypography from 'components/MKTypography';

// Images
import logoCT from 'assets/images/logo-ct-dark.png';

const date = new Date().getFullYear();

const SOCIAL_NAME = 'solworksai';

export default {
  brand: {
    name: 'SOLWORKS.AI',
    image: logoCT,
    route: '/'
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: `https://www.facebook.com/${SOCIAL_NAME}`
    },
    {
      icon: <LinkedinIcon />,
      link: `https://www.linkedin.com/company/${SOCIAL_NAME}`
    },
    {
      icon: <TwitterIcon />,
      link: `https://twitter.com/${SOCIAL_NAME}`
    },
    {
      icon: <InstagramIcon />,
      link: `https://instagram.com/${SOCIAL_NAME}`
    },
    {
      icon: <YouTubeIcon />,
      link: `https://www.youtube.com/channel/${SOCIAL_NAME}`
    }
  ],
  menus: [
    {
      name: 'company',
      items: [
        { name: 'about us', href: '#' },
        { name: 'blog', href: '#' }
      ]
    },
    {
      name: 'resources',
      items: [
        { name: 'FAQs', href: '#' },
        { name: 'affiliate program', href: '#' }
      ]
    },
    {
      name: 'help & support',
      items: [
        { name: 'contact us', href: '#' },
        { name: 'knowledge center', href: '#' }
      ]
    },
    {
      name: 'legal',
      items: [
        { name: 'terms & conditions', href: '#s' },
        { name: 'privacy policy', href: '#' }
      ]
    }
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}{' '}
      <MKTypography
        component="a"
        href="#"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular">
        SOLWORKS.AI
      </MKTypography>
      .
    </MKTypography>
  )
};
