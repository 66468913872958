/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';
// @mui material components
// Material Kit 2 React components

// Material Kit 2 React examples
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import PropertyStats from './PropertyStats';
import PropertySunlight from './PropertySunlight';

const PropertyInfo = ({ estimate }) => {
  const {address, stats, sunlight} = estimate;
  const {street, locality} = address;
  const sunStats = [
    { id: 1, label: 'Total Sunlight Hours', value: sunlight?.yearTotal },
    { id: 2, label: 'Peak Sunlight Hours', value: sunlight?.yearPeak },
  ]
  return (
    <Box sx={{ width: '100%', flexDirection: 'column' }}>
      <Typography sx={styles.label}>
        {street || ''}
      </Typography>
      <Typography sx={styles.label}>
        {locality || ''}
      </Typography>
      {stats && <PropertyStats stats={stats} />}
      {sunlight && <PropertySunlight stats={sunStats} />}
    </Box>
  );
};

export default PropertyInfo;

const styles = {
  label: {
    fontSize: 13,
    fontWeight: '600',
  },
};