/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';
import PropTypes from 'prop-types';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';
import AddressSearch from 'components/AddressSearch';

const AddressForm = ({ onComplete, onCalculate, disabled }) => {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 4, md: 0 }}>
      <MKBox
        variant="gradient"
        // bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{
          overflow: 'hidden',
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.55),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl
        }}>
        <Container sx={{ position: 'relative', zIndex: 2, py: 2 }}>
          <Grid container item xs={12} lg={10} sx={{ mx: 'auto' }}>
            <MKBox width="100%" component="form" method="post" autoComplete="off">
              <MKBox p={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <RadioGroup
                      sx={{ flexDirection: { xs: 'column', md: 'row' } }}
                      aria-labelledby="install-radio"
                      name="install-time"
                      defaultValue={1}>
                      <FormControlLabel
                        sx={style.labelStyle}
                        value={1}
                        control={<Radio />}
                        label="OWN"
                      />
                      <FormControlLabel
                        sx={style.labelStyle}
                        value={2}
                        control={<Radio />}
                        label="RENT"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AddressSearch onComplete={onComplete} />
                  </Grid>
                  <Grid item justifyContent="center" xs={12} md={3}>
                    <MKButton
                      onClick={onCalculate}
                      fullWidth
                      variant="gradient"
                      color="info"
                      size="large"
                      disabled={disabled}
                      sx={{ opacity: disabled ? 0.5 : 1 }}
                      component="a">
                      Calculate
                    </MKButton>
                  </Grid>
                </Grid>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
};

export default AddressForm;

AddressForm.propTypes = {
  onComplete: PropTypes.func,
  onCalculate: PropTypes.func,
  disabled: PropTypes.bool
};

const style = {
  labelStyle: {
    '& .MuiFormControlLabel-label': {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.75)'
    }
  },
  input: {
    backgroundColor: 'rgba(255,255, 255, 0.95)',
    borderRadius: 2
  }
};
