import axios from 'axios';

const Api = axios.create({
  baseURL: '//api.solworks.ai/api',
  headers: {}
});

// const addSession = (session) => {
//   IcarusApi.defaults.headers.common.session = session;
// };

// export default Api;

export const service = {
  getSession: (payload) => Api.get('/session', { params: payload }),
  getEstimate: (payload) => Api.post('/calculate', payload),
  postContact: (payload) => Api.post('/contacts', payload),
  put: (url, params) => Api.put(url, params),
  delete: (url, params) => Api.delete(url, params),
  getTest: () => console.log('test')
};
