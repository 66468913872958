import React, { useEffect, useRef } from 'react';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';

// Material Kit 2 React examples
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import DefaultFooter from 'examples/Footers/DefaultFooter';
import FilledInfoCard from 'examples/Cards/InfoCards/FilledInfoCard';

// Presentation page sections
import AddressForm from 'pages/Presentation/sections/AddressForm';
import InfoBlock from 'pages/Presentation/sections/InfoBlock';
import DataBlocks from 'pages/Presentation/sections/DataBlocks';
import ContactForm from 'pages/Presentation/sections/ContactForm';
import AlertModal from './sections/AlertModal';

// Presentation page components
import BuiltByDevelopers from 'pages/Presentation/components/BuiltByDevelopers';

// Routes
import routes from 'routes';
import footerRoutes from 'footer.routes';

// Images
// import bgImage from 'assets/images/bg-presentation.jpg';
import bgImage from 'assets/images/bg-panels.jpg';
import { useStore } from 'hooks';

function Presentation() {
  const { alert, estimate, place, setPlace, getEstimate, reCalculate, postContact, setAlert } =
    useStore();
  // const handleAddress = useCallback((address) => {
  //   console.log('HANDLE ADDRESS', address);
  //   setPlace(address);
  // }, []);
  const handleUpdate = (data) => {
    console.log('HANDLE UPDATE', data);
    reCalculate(data);
  };

  const handleAlert = () => {
    setAlert({});
  };

  useEffect(() => {
    console.log('PRESENTATION PAGE', estimate);
  }, [estimate]);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: 'external',
          route: '#request-call',
          label: 'REQUEST A CALL',
          color: 'info'
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          display: 'grid',
          placeItems: 'center',
          opacity: 0.95
        }}
      />
      <Container>
        <Grid
          container
          item
          mt={-40}
          xs={12}
          lg={8}
          justifyContent="center"
          mx="auto"
          sx={{ position: 'relative', zIndex: 2 }}>
          <MKTypography
            variant="hero"
            color="white"
            fontWeight="light"
            mb={1}
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down('md')]: {
                fontSize: size['3xl']
              }
            })}>
            Solar Calculator{' '}
          </MKTypography>
          <MKTypography
            variant="body1"
            color="white"
            textAlign="center"
            px={{ xs: 6, lg: 12 }}
            sx={{ textShadow: '4px 4px 5px #615f5f' }}
            mb={4}
            mt={1}>
            Solar energy is the most abundant energy resource on earth, solar is an important part
            of our clean energy future. Enter your address to get your solar estimate using our AI
            powered solar calculator.
          </MKTypography>
        </Grid>
      </Container>
      <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'transparent' }}>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            maxWidth: { md: 1200 },
            mt: 1,
            mb: 4,
            // backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            // backdropFilter: 'saturate(200%) blur(30px)',
            // boxShadow: ({ boxShadows: { xxl } }) => xxl
            border: 'none !important',
            backgroundColor: 'transparent !important',
            background: 'transparent !important',
            boxShadow: 'none !important'
          }}>
          <AddressForm
            onComplete={setPlace}
            onCalculate={getEstimate}
            disabled={place?.address ? false : true}
          />
          <InfoBlock estimate={estimate} coordinates={place?.coordinates} onChange={handleUpdate} />
          <DataBlocks estimate={estimate} />
          <ContactForm onRequest={postContact} />
          <Container sx={{ mt: 1 }}>
            <BuiltByDevelopers />
          </Container>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <FilledInfoCard
                  variant="gradient"
                  color="info"
                  icon="flag"
                  title="Tax Incentives"
                  description="Take advantage of available government tax benefits for switching to solar. These incentives can further reduce your overall costs and accelerate your return on investment."
                  // action={{
                  //   type: 'external',
                  //   route: 'https://www.creative-tim.com/learning-lab/react/overview/material-kit/',
                  //   label: "Let's start"
                  // }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FilledInfoCard
                  variant="gradient"
                  color="info"
                  icon="precision_manufacturing"
                  title="Hassle-Free Install"
                  description="We handle all aspects of installation, from obtaining necessary permits to mounting the panels, so you can start enjoying the benefits of solar energy without any stress."
                  // action={{
                  //   type: 'external',
                  //   route: 'https://www.creative-tim.com/learning-lab/react/overview/datepicker/',
                  //   label: 'Read more'
                  // }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FilledInfoCard
                  variant="gradient"
                  color="info"
                  icon="apps"
                  title="Increase Home Value"
                  description="Investing in solar power enhances the value of your property. Potential buyers are increasingly interested in energy-efficient homes, and having solar installed will make your home stand out in the real estate market."
                  // action={{
                  //   type: 'external',
                  //   route: 'https://www.creative-tim.com/learning-lab/react/alerts/material-kit/',
                  //   label: 'Read more'
                  // }}
                />
              </Grid>
            </Grid>
          </Container>
          {/* <Testimonials /> */}
        </Card>
      </div>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <AlertModal alert={alert} onClose={handleAlert} />
    </>
  );
}

export default Presentation;
