export default [
  {
    title: 'Usage',
    description: 'Average monthly and yearly estimates',
    color: 1,
    items: [
      {
        name: 'Avg monthly usage',
        count: 1000,
        suffix: 'kWh',
        icon: 'ph:lightning-fill'
      },
      {
        name: 'Avg monthly cost',
        prefix: '$',
        count: 144.07,
        icon: 'ph:currency-circle-dollar-fill'
      },
      {
        name: 'Avg yearly usage',
        suffix: 'kWh',
        count: 11997,
        icon: 'ph:lightning-fill'
      },
      {
        name: 'Avg yearly cost',
        prefix: '$',
        count: 1728.84,
        icon: 'ph:smiley-sad-fill'
      }
    ]
  },
  {
    title: 'Solar Estimates',
    description: 'Estimates based on your energy usage',
    color: 2,
    items: [
      {
        name: 'Total Panels',
        count: 18,
        icon: 'ph:windows-logo-fill'
      },
      {
        name: 'Total Cost',
        count: 22000,
        icon: 'ph:currency-circle-dollar-fill'
      },
      {
        name: 'Monthly Cost',
        count: 140,
        icon: 'ph:currency-circle-dollar-fill'
      },
      {
        name: 'Initial Cost',
        count: 0,
        icon: 'ph:smiley-fill'
      }
    ]
  }
  // {
  //   title: 'Carbon Offset',
  //   description: 'Estimates based on your energy usage',
  //   color: 3,
  //   items: [
  //     {
  //       name: 'CO2',
  //       count: 4,
  //       icon: 'ph:plant-fill'
  //     },
  //     {
  //       name: 'Trees',
  //       count: 2,
  //       icon: 'ph:tree-fill'
  //     },
  //     {
  //       name: 'Cars',
  //       count: 3,
  //       icon: 'ph:car-simple-fill'
  //     },
  //     {
  //       name: 'Miles',
  //       count: 4,
  //       icon: 'ph:gauge-fill'
  //     }
  //   ]
  // }
];

export const formatData = (estimate) => {
  const data = [
    {
      title: 'Usage',
      description: 'Average monthly and yearly estimates',
      color: 1,
      items: [
        {
          name: 'Avg monthly usage',
          count: estimate?.usage?.energy?.month || 0,
          suffix: 'kWh',
          icon: 'ph:lightning-fill'
        },
        {
          name: 'Avg monthly cost',
          prefix: '$',
          count: estimate?.usage?.cost?.month || 0,
          icon: 'ph:currency-circle-dollar-fill'
        },
        {
          name: 'Avg yearly usage',
          suffix: 'kWh',
          count: estimate?.usage?.energy?.year || 0,
          icon: 'ph:lightning-fill'
        },
        {
          name: 'Avg yearly cost',
          prefix: '$',
          count: estimate?.usage?.cost?.year || 0,
          icon: 'ph:smiley-sad-fill'
        }
      ]
    },
    {
      title: 'Solar Estimates',
      description: 'Estimates based on your energy usage',
      color: 2,
      items: [
        {
          name: 'Total Panels',
          count: estimate?.solar?.panels?.total || 0,
          icon: 'ph:windows-logo-fill'
        },
        {
          name: 'Total Cost',
          count: estimate?.solar?.cost?.net || 0,
          icon: 'ph:currency-circle-dollar-fill'
        },
        {
          name: 'Monthly Cost',
          count: estimate?.solar?.cost?.month || 0,
          icon: 'ph:currency-circle-dollar-fill'
        },
        {
          name: 'Initial Cost',
          count: 0,
          icon: 'ph:smiley-fill'
        }
      ]
    }
    // {
    //   title: 'Carbon Offset',
    //   description: 'Estimates based on your energy usage',
    //   color: 3,
    //   items: [
    //     {
    //       name: 'CO2',
    //       count: estimate?.solar?.offset?.co2 || 0,
    //       icon: 'ph:plant-fill'
    //     },
    //     {
    //       name: 'Trees',
    //       count: estimate?.solar?.offset?.trees || 0,
    //       icon: 'ph:tree-fill'
    //     },
    //     {
    //       name: 'Cars',
    //       count: estimate?.solar?.offset?.cars || 0,
    //       icon: 'ph:car-simple-fill'
    //     },
    //     {
    //       name: 'Miles',
    //       count: estimate?.solar?.offset?.miles || 0,
    //       icon: 'ph:gauge-fill'
    //     }
    //   ]
    // }
  ];

  return data;
};
